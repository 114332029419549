import React, { Component } from 'react';
import withSplashScreen from './withSplashScreen';
import { Link } from 'react-router-dom';
import './login.css';

class Login extends React.Component {
    fontStyle = {
        fontSize: this.getFontSize(),
        paddingBottom: this.props.isMobile ? 0 : 1000,
        height: this.props.isMobile ? '100%' : 'auto'
    }
    boxStyle = {
        top: ".8rem",
        width: "1.25rem",
        height: "1.25rem"
    }
    buttonStyle = {
        fontSize: window.api?.isElectron ? "1.6vw" : (window.screen.height < 1080 ? "0.75rem" : "1rem"),
        padding: window.api?.isElectron ? "0.8vw" : (window.screen.height < 1080 ? "0.25rem" : "0.5rem")
    }

    getFontSize() {
        if (window.api?.isElectron)
            return "2.62vw";
        if (window.screen.height >= 1080 || !this.props.isWeb)
            return `calc(18px + (26 - 18) * (100vw - 340px) / (2160 - 340))`;
        
        return `calc(16px + (20 - 16) * (100vw - 340px) / (2160 - 340))`;
    }

    render() {
        return (
            <div className="container" style={this.fontStyle}>
                <div className="row justify-content-center">
                    <form className="form-horizontal m-2 col-8">
                        <div className="form-group merchant">
                            <div className="col-10 d-flex justify-content-center">
                                <label className={this.props.isMobile ? "col control-label" : "col-4 control-label"} htmlFor="SoTName">Name</label>
                                <div className={this.props.isMobile ? "col" : "col-4"}>
                                    <input className="form-control" id="SoTName" placeholder="Enter Name" value={this.props.username} onChange={this.props.onUsernameChange} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group merchant">
                            <div className="col-10 d-flex justify-content-center">
                                <label className={this.props.isMobile ? "col control-label" : "col-4 control-label"} htmlFor="crewSize">Crew Size</label>
                                <div className={this.props.isMobile ? "col" : "col-4"}>
                                    <select className="form-control" id="crewSize" value={this.props.crewSize} onChange={this.props.onCrewSizeChange} >
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-center">
                                    <div hidden={this.props.isMobile} className="checkbox col">
                                        <input style={this.boxStyle} id="streamerCheck" type="checkbox" checked={this.props.streamerMode} onChange={this.props.onStreamerModeChange} />
                                        <label htmlFor="streamerCheck" className="check">
                                            <span className="merchant">Streamer Mode</span>
                                        </label>
                                    </div>
                                    <div className="checkbox col">
                                        <input style={this.boxStyle} id="muteCheck" type="checkbox" checked={this.props.muteAudio} onChange={this.props.onMuteChange} />
                                        <label htmlFor="muteCheck" className="check">
                                            <span className="merchant">Mute Audio</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="col d-flex justify-content-center">
                                <div className="col-8 disabled">
                                    <button style={this.buttonStyle} onClick={this.props.onClickNext} className={"button-55" + (this.props.username == "" ? " disableBtn" : "")}>Start</button>
                                    {/*<Link to={{ pathname: "/tutorial" }} className={"button-55" + (this.props.username == "" ? " disableBtn" : "")}>Start</Link>*/}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            );
    }
}

export default withSplashScreen(Login);