import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Shop from './shop';
import { Link } from 'react-router-dom';
import './barBase.css';

class BarBase extends React.Component {
    challengeT1Checked = false;
    challengeT2Checked = false;
    challengeT3Checked = false;

    topStyle = {
        paddingBottom: this.props.isMobile ? 0 : 200,
        height: this.props.isMobile ? '100%' : 'auto'
    }
    challengeStyle = {
        fontSize: this.getChallengeFontSize()
    }
    titleStyle = {
        fontSize: this.getTitleFontSize(),
        marginBottom: 0
    }
    boxStyle = {
        top: ".8rem",
        width: "1.25rem",
        height: "1.25rem"
    }
    buttonStyle = {
        fontSize: window.api?.isElectron ? "1.6vw" : (window.screen.height < 1080 ? "0.75rem" : "1rem"),
        padding: window.api?.isElectron ? "0.8vw" : (window.screen.height < 1080 ? "0.25rem" : "0.5rem")
    }

    getChallengeFontSize() {
        if (window.api?.isElectron)
            return "3.2vw";
        if (window.screen.height >= 1080 || !this.props.isWeb)
            return `calc(18px + (26 - 18) * (100vw - 340px) / (2160 - 340))`;
        
        return `calc(16px + (20 - 16) * (100vw - 340px) / (2160 - 340))`;
    }

    getTitleFontSize() {
        if (window.api?.isElectron)
            return "5vw";
        if (window.screen.height >= 1080 || !this.props.isWeb)
            return `calc(32px + (40 - 32) * (100vw - 340px) / (2160 - 340))`;
        
        return `calc(30px + (34 - 30) * (100vw - 340px) / (2160 - 340))`;
    }

    render() {
        return (
            <div style={this.topStyle}>
                <div className="container">
                    <div className="row g-0 justify-content-center">
                        <div className="col">
                            <button id="sinkBtn" style={this.buttonStyle} type="button" className="button-55 col" onClick={this.props.onAddSink} >+1 Sink</button>
                            <button style={this.buttonStyle} onClick={this.props.onClickNext} className="button-55 col">End</button>
                            {/*<Link to={{ pathname: "/stats" }} className="button-55 col" onClick={this.props.onLogOut}>End</Link>*/}
                        </div>
                    </div>
                </div>
                <p style={this.titleStyle} className="merchant">Feats (Optional)</p>
                <div className="d-flex justify-content-center">
                    <div style={this.challengeStyle} className="text-start">
                        <input style={this.boxStyle} disabled={this.props.challengeT1Checked ? true : false} id="challengeT1Check" type="checkbox" onChange={this.props.onT1Challenge} />
                        <label htmlFor="challengeT1Check" className={this.props.challengeT1Checked ? "opacity-50 px-1 check" : "px-1 check"}>
                            <span className="strike">{this.props.challengeT1}</span>
                        </label>
                        <br></br>
                        <input style={this.boxStyle} disabled={this.props.challengeT2Checked ? true : false} id="challengeT2Check" type="checkbox" onChange={this.props.onT2Challenge} />
                        <label htmlFor="challengeT2Check" className={this.props.challengeT2Checked ? "opacity-50 px-1 check" : "px-1 check"}>
                            <span className="strike">{this.props.challengeT2}</span>
                        </label>
                        <br></br>
                        <input style={this.boxStyle} disabled={this.props.challengeT3Checked ? true : false} id="challengeT3Check" type="checkbox" onChange={this.props.onT3Challenge} />
                        <label htmlFor="challengeT3Check" className={this.props.challengeT3Checked ? "opacity-50 px-1 check" : "px-1 check"}>
                            <span className="strike">{this.props.challengeT3}</span>
                        </label>
                    </div>
                </div>
                <Shop onPurchase={this.props.onPurchase} silver={this.props.silver} items={this.props.items} upgrades={this.props.upgrades} isMobile={this.props.isMobile} isWeb={this.props.isWeb} />
            </div>);
    }
}

export default BarBase;//withRouter(BarBase);