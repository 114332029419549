import React, { Component } from 'react';
import ShopItem from './shopItem';

class Shop extends React.Component {
    rowStyle = {
        height: window.api?.isElectron ? "9.5vw" : (this.props.isMobile || window.screen.height < 1080) ? 75 : 95
    };

    colStyle = {
        width: window.api?.isElectron ? "9.5vw" : (this.props.isMobile || window.screen.height < 1080) ? 75 : 95
    };
    titleStyle = {
        fontSize: this.getTitleFontSize(),
        marginBottom: 0
    }

    getTitleFontSize() {
        if (window.api?.isElectron)
            return "5vw";
        if (window.screen.height >= 1080 || !this.props.isWeb)
            return `calc(32px + (40 - 32) * (100vw - 340px) / (2160 - 340))`;
        
        return `calc(30px + (34 - 30) * (100vw - 340px) / (2160 - 340))`;
    }

    render() {
        const arrayChunk = (arr, n) => {
            const array = arr.slice();
            const chunks = [];
            while (array.length) chunks.push(array.splice(0, n));
            return chunks;
        }

        return (
            <div>
                <p style={this.titleStyle} className="merchant">Upgrades</p>
                <div className="container">
                    {arrayChunk(this.props.upgrades, 3).map(row =>
                        <div className="row g-0 justify-content-center" style={this.rowStyle}>
                            {row.map(item =>
                                <div className="h-100" style={this.colStyle}>
                                    <ShopItem key={item.id} onPurchase={this.props.onPurchase} item={item} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <p style={this.titleStyle} className="merchant">Items</p>
                <div className="container">
                    {arrayChunk(this.props.items, (window.screen.height < 1080 && this.props.isMobile && !window.api?.isElectron ? 4 : 3)).map(row =>
                        <div className="row g-0 justify-content-center" style={this.rowStyle}>
                            {row.map(item =>
                                <div style={this.colStyle}>
                                    <ShopItem key={item.id} onPurchase={this.props.onPurchase} item={item} isMobile={this.props.isMobile}/>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>);
    }
}

export default Shop;