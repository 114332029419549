import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {  TwitterShareButton } from 'react-twitter-embed';
import wanted from '../images/blurbsWanted.png';
import portrait1 from '../images/portrait1.png';
import portrait2 from '../images/portrait2.png';
import portrait3 from '../images/portrait3.png';
import portrait4 from '../images/portrait4.png';
import portrait5 from '../images/portrait5.png';
import goat from '../images/goat2.png';
import insta from '../images/Instagram.png';
import discord from '../images/discord.png';
import twitter from '../images/Twitter-logo.png';
import youtube from '../images/youtube.png';
import twitch from '../images/twitch.png';
import goatAudio from '../audio/goat.mp3'

class Stats extends Component {
    fontStyle = {
        fontSize: this.getFontSize(),
        paddingBottom: this.props.isMobile ? 0 : 200,
        marginTop: window.api?.isElectron ? "-7.5vw" : (window.screen.height < 1080 ? -60 : -75), 
        height: this.props.isMobile ? '100%' : 'auto'
    }
    titleStyle = {
        fontSize: this.getTitleFontSize(),
        marginBottom: 0
    }
    upgradesStyle = {
        width: window.api?.isElectron ? "5vw" : (window.screen.height < 1080 ? 40 : 50),
        height: "auto"
    }
    iconStyle = {
        width: "auto",
        height: window.api?.isElectron ? "5vw" : (window.screen.height < 1080 ? 40 : "auto"),
    }
    wantedStyle = {
        position: "relative",
        zIndex: "0",
        height: this.getWantedSize()
    }
    wantedTitleStyle = {
        fontSize:  window.api?.isElectron ? "5.7vw" : "auto",
        marginBottom: 0,
        fontWeight: "bold"
    }
    zStyle = {
        zIndex: "5"
    }
    buttonStyle = {
        fontSize: window.api?.isElectron ? "1.6vw" : (window.screen.height < 1080 ? "0.75rem" : "1rem"),
        padding: window.api?.isElectron ? "0.8vw" : (window.screen.height < 1080 ? "0.25rem" : "0.5rem")
    }

    getFontSize() {
        if (window.api?.isElectron)
            return "3.2vw";
        if (window.screen.height >= 1080 || !this.props.isWeb)
            return `calc(18px + (26 - 18) * (100vw - 340px) / (2160 - 340))`;
        
        return `calc(16px + (20 - 16) * (100vw - 340px) / (2160 - 340))`;
    }

    getTitleFontSize() {
        if (window.api?.isElectron)
            return "5vw";
        if (window.screen.height >= 1080 || !this.props.isWeb)
            return `calc(32px + (40 - 32) * (100vw - 340px) / (2160 - 340))`;
        
        return `calc(30px + (34 - 30) * (100vw - 340px) / (2160 - 340))`;
    }

    getWantedSize() {
        if (window.api?.isElectron)
            return "40vw";
        if (window.screen.height >= 1440)
            return 400;
        if (window.screen.height >= 1080 && window.screen.height < 1440 || !this.props.isWeb)
            return 345;
        
        return 250;
    }

    getWantedClass() {
        if (window.api?.isElectron)
            return "merchant";
        if (window.screen.height >= 1080 || !this.props.isWeb)
            return "display-3 merchant";
        
        return "display-5 merchant";
    }

    getTweetText = () => {
        let tweet = "🏴‍☠️#SeaOfThieves #ScurvyScroll📜\n" +
        `👥${this.props.crewSize}   💰${this.props.silver.toLocaleString()}   ☠️${this.props.shipsSunk}   ✅${this.props.challengesCompleted}`;
        if(this.props.upgrades.some(upgrade => upgrade.disabled)) {
            tweet += `\n🔓:  ${this.props.upgrades.filter(upgrade => upgrade.disabled).map(upgrade => upgrade.emoji).toString().replaceAll(",", "  ")}`;
        }
        if(this.props.items.some(item => item.amount > 0)) {
            tweet += `\n${this.props.items.filter(item => item.amount > 0).map(item => `${item.emoji + item.amount.toString()} `).toString().replaceAll(",", "  ")}`;
        }

        return tweet;
    }

    onDiscordClicked() {
        window.open("https://discord.gg/zrSnf4JvKw");
    }

    onYoutubeClicked() {
        window.open("https://www.youtube.com/blurbs");
    }

    onTwitterClicked() {
        window.open("https://twitter.com/Blurbstv");
    }

    onInstaClicked() {
        window.open("https://www.instagram.com/blurbsttv/");
    }

    onTwitchClicked() {
        window.open("https://www.twitch.tv/blurbs");
    }

    onCapiClicked() {
        window.open("https://twitter.com/Capi974");
    }

    isBoxy() {
        let name = this.props.username.replace(' ','').toLowerCase();
        return name == "boxy" || name == "boxyfresh";
    }

    playAudio = (sound) => {
        if (this.props.muteAudio)
          return;
  
        switch (sound) {
          case "goat":
            (new Audio(goatAudio)).play();
            break;
        }
      }

      getTitle() {
        if (this.isBoxy())
          return "Goat";

        let title = "Swabbie";
        let silver = this.props.totalSilver;
        if (silver >= 25000 && silver < 50000)
            title = "Deckhand";
        else if (silver >= 50000 && silver < 75000)
            title = "Corsair";
        else if (silver >= 75000 && silver < 100000)
            title = "Captain";
        else if (silver >= 100000)
            title = "Legend";

        return title;
      }

      getPortrait() {
        if (this.isBoxy())
          return goat;

        let portrait = portrait1;
        let silver = this.props.totalSilver;
        if (silver >= 25000 && silver < 50000)
            portrait = portrait2;
        else if (silver >= 50000 && silver < 75000)
            portrait = portrait3;
        else if (silver >= 75000 && silver < 100000)
            portrait = portrait4;
        else if (silver >= 100000)
            portrait = portrait5;

        return portrait;
      }
    
    render() { 
        if (this.isBoxy())
            this.playAudio("goat");
        return (
            <div style={this.fontStyle}>
                <p style={this.wantedTitleStyle} className={this.getWantedClass()}>WANTED</p>
                <div className="d-flex justify-content-center">
                    <img style={this.wantedStyle} src={this.getPortrait()}/>
                </div>
                <p style={this.titleStyle} className="merchant">{this.getTitle()} {this.props.username}</p>
                <p style={this.titleStyle} className="merchant">Reward: {this.props.silver.toLocaleString()}</p>
                <span style={this.zStyle}>
                    👥 {this.props.crewSize} ☠️ {this.props.shipsSunk} ☑️ {this.props.challengesCompleted}<br></br>
                    Upgrades: {this.props.upgrades.filter(upgrade => upgrade.disabled).map(upgrade => 
                        <img style={this.upgradesStyle} src={upgrade.image2} className=""/>)}
                    <br></br>
                    <div className="d-flex justify-content-center">
                    Items: 
                        {this.props.items.filter(item => item.amount > 0).map(item => 
                        <div>
                            <img style={this.upgradesStyle} src={item.image2} className=""/> <span>{item.amount}</span>
                        </div>
                    )} 
                    </div>
                    <div className="d-flex justify-content-center">
                        <span className="mx-1">Generate a Tweet with your results!</span>
                        <div className="mx-1">
                                <TwitterShareButton
                                //onLoad={function noRefCheck(){}}
                                options={{
                                    buttonHashtag: undefined,
                                    screenName: undefined,
                                    text: `${this.getTweetText()}`,
                                    via: '',
                                    size: 'large'
                                }}
                                url=" "
                                />
                        </div>
                    </div>
                    Created by <span className="link-primary" onClick={this.onTwitchClicked} role="button">twitch.tv/blurbs</span> | Art by <span className="link-primary" onClick={this.onCapiClicked} role="button">Capi</span>
                </span> <br></br>
                <div className="d-flex justify-content-center">
                    <div style={this.iconStyle} className="d-flex justify-content-between">
                        <img className="px-1 btn" src={twitch} onClick={this.onTwitchClicked}/>
                        <img className="px-1 btn" src={youtube} onClick={this.onYoutubeClicked}/>
                        <img className="px-1 btn" src={discord} onClick={this.onDiscordClicked}/>
                        <img className="px-1 btn" src={twitter} onClick={this.onTwitterClicked}/>
                        <img className="px-1 btn" src={insta} onClick={this.onInstaClicked}/>
                    </div>
                </div>
                {/*<Link to={{ pathname: "/" }} className="button-55 mb-5" onClick={this.props.onEndSession}>New Game</Link>*/}
                <button style={this.buttonStyle} onClick={this.props.onClickNext} className="button-55 mb-5">New Game</button>
            </div>
        );
    }
}
 
export default Stats;