import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './tutorial.css';

class Tutorial extends Component {
    fontStyle = {
        fontSize: this.getFontSize(),
        paddingBottom: this.props.isMobile ? 0 : 500,
        height: this.props.isMobile ? '100%' : 'auto'
    }
    buttonStyle = {
        fontSize: window.api?.isElectron ? "1.6vw" : (window.screen.height < 1080 ? "0.75rem" : "1rem"),
        padding: window.api?.isElectron ? "0.8vw" : (window.screen.height < 1080 ? "0.25rem" : "0.5rem")
    }

    getFontSize() {
        if (window.api?.isElectron)
            return "2.62vw";
        if (window.screen.height >= 1080 || !this.props.isWeb)
            return `calc(16px + (24 - 16) * (100vw - 340px) / (2160 - 340))`;
        
        return `calc(16px + (20 - 16) * (100vw - 340px) / (2160 - 340))`;
    }
    
    render() {
        return (
            <div className="d-flex justify-content-center" style={this.fontStyle}>
                <div className="col">
                    <div hidden={this.props.isMobile} className="pulse">
                        <span>
                            ↑ <br></br>
                            Click top of scroll to collapse/expand
                        </span>
                        {window.api?.isElectron ? <span><br></br>(or CTRL + SHIFT + F)</span> : ""}
                    </div>
                    <div hidden={!this.props.isMobile} className="pulse">
                        <span>
                            ↓ <br></br>
                            Scrolling required on some devices
                        </span>
                    </div>
                    <span><br></br>Welcome to Scurvy Scroll! <br></br>
                        You must start with only pistol and bananas.<br></br>
                        Buy weapons/food tiers as one-time upgrades.<br></br>
                        Cannonballs and planks are free.<br></br>
                        All other items are purchased as needed.<br></br>
                        Earn Silver by sinking ships and doing optional feats.<br></br>
                        Feats vary in difficulty and value.<br></br>
                        Feats also refresh after sinking a ship.<br></br>
                        Press "+1 Sink" button when sinking enemy ships.<br></br>
                        You earn less Silver per sink the bigger your crew.<br></br>
                        Refrain from attacking new ships docked at outposts.<br></br>
                        Keep supplies you've "purchased" in a separate barrel.<br></br>
                        Get the most Silver you can without using too much!<br></br>
                        The voyage ends once your ship sinks.<br></br>
                        Once you've sunk hit the "End" button for your stats. <br></br>
                        Refreshing the app will <b>lose your progress!</b><br></br>
                        Good luck!
                    </span>
                    <div className="d-flex justify-content-center">
                        <button style={this.buttonStyle} onClick={this.props.onClickNext} className="button-55">Ok</button>
                        {/*<Link to={{ pathname: "/barBase" }} className="button-55" onClick={this.props.onStart}>Ok</Link>*/}
                    </div>
                </div>
            </div>
            
        );
    }
}
 
export default Tutorial;