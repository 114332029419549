import React, { Component } from 'react';
import silverIcon from '../images/silver.png';

class Nav extends Component {
    state = {  } 

    silverIconStyle = {
        width: window.api?.isElectron ? "2.6vw" : (window.screen.height < 1080 ? 20 : 26)
    }
    fontStyle = {
        fontSize: this.getFontSize()
    }
    navStyle = {
        height: window.api?.isElectron ? "7.5vw" : (window.screen.height < 1080 ? 60 : 75)
    }
    upgradesStyle = {
        width: window.api?.isElectron ? "3vw" : (window.screen.height < 1080 ? 23 : 30),
        height: "auto",
        marginLeft: -2,
        marginRight: -2
    }
    positionStyle = {
        position: "relative",
        marginTop: "-13px"
    }

    carouselStyle = {
        //width: "75%"
    }

    getFontSize() {
        if (window.api?.isElectron)
            return "2.2vw";
        if (window.screen.height >= 1080 || !this.props.isWeb)
            return 22;
        
        return `calc(14px + (18 - 14) * (100vw - 340px) / (2160 - 340))`;
    }

    render() { 
        console.log("FONT :" + (window.innerHeight / 100) * 2);
        return (
            <nav id="navBar" className="navbar navbar-light bg-light d-flex justify-content-between p-2 bg-transparent" style={this.navStyle}>
                <span id="silver" hidden={!this.props.loggedIn} className="navbar-brand mx-0 mb-1 col" style={this.fontStyle}>
                    <img style={this.silverIconStyle} src={silverIcon} className="mx-1 mb-2"/>{this.props.silver.toLocaleString()}
                </span>
                <div hidden={!this.props.loggedIn || this.props.isMobile} style={this.carouselStyle} className="col-8 mb-2">
                    <div className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div id="challengeSlide1" className="carousel-item active" data-bs-interval="3000">
                                <span style={this.fontStyle}>{this.props.challengeT1}</span>
                            </div>
                            <div id="challengeSlide2" className="carousel-item" data-bs-interval="3000">
                                <span style={this.fontStyle}>{this.props.challengeT2}</span>
                            </div>
                            <div id="challengeSlide3" className="carousel-item" data-bs-interval="3000">
                                <span style={this.fontStyle}>{this.props.challengeT3}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={!this.props.loggedIn} className="col overflow-hidden" style={this.positionStyle}>
                    {this.props.upgrades.filter(upgrade => upgrade.disabled).map(upgrade => 
                        <img style={this.upgradesStyle} src={upgrade.image2} className="mb-0"/>
                    )}
                </div>
            </nav>
        );
    }
}
 /*
{[{challenge: this.props.challengeT1, checked: this.props.challengeT1Checked}, {challenge: this.props.challengeT2, checked: this.props.challengeT2Checked}, {challenge: this.props.challengeT3, checked: this.props.challengeT3Checked}]
.filter(x => !x.checked).map((x, n) => 
    <div className={"carousel-item" + (n == 0 ? " active" : "")} data-bs-interval="3000">
        <span>{x.challenge}</span>
    </div>
)}
 */
export default Nav;