import React, { Component } from 'react';

class ShopItem extends React.Component {
    buttonStyle = {
        width: window.api?.isElectron ? "9.5vw" : (this.props.isMobile || window.screen.height < 1080) ? 75 : 95,
        height: window.api?.isElectron ? "9.5vw" : (this.props.isMobile || window.screen.height < 1080) ? 75 : 95
    };

    render() {
        return (
            <div className="d-grid h-100">
                    <img id={this.props.item.id} style={this.buttonStyle} src={this.props.item.image} onClick={() => this.props.onPurchase(this.props.item)} className={"btn p-0" + (this.props.item.disabled ? " opacity-50" : "")}/>
            </div>
        );
    }

//{this.props.item.price} - {this.props.item.name}
}

export default ShopItem;